import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from '../../components/en/common/Layout'
import { MetaData } from '../../components/common/meta'
import HeroFullScreenPhoto from '../../components/sections/hero-full-screen-photo'
import WhyThinkPlus from '../../components/en/sections/why-think-plus'
import OurPartners from '../../components/sections/our-partners'
import Testimonials from '../../components/en/sections/testimonials-static'
import ContactForm from '../../components/en/sections/contact-form'

import '../../styles/pages/page-landingpage.scss'
import '../../styles/pages/page-mailchimpexperts.scss'
import { mailchimpexperts as servicesSchema } from '../../utils/schema/en/services'

const pageMailchimpCertified = ({ location }) => {
  const data = require('../../utils/schema/en/pages/mailchimpMarketing.json')
  const images = useStaticQuery(graphql`{
  hero: file(relativePath: {eq: "hero/hero-mailchimpexperts.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 90, layout: FULL_WIDTH)
    }
  }
  monkey: file(relativePath: {eq: "mailchimpexperts/monkey.png"}) {
    childImageSharp {
      gatsbyImageData(width: 400, layout: CONSTRAINED)
    }
  }
  macbook: file(relativePath: {eq: "mailchimpexperts/macbook.png"}) {
    childImageSharp {
      gatsbyImageData(width: 870, layout: CONSTRAINED)
    }
  }
  iphones: file(relativePath: {eq: "mailchimpexperts/iphones.png"}) {
    childImageSharp {
      gatsbyImageData(width: 870, layout: CONSTRAINED)
    }
  }
  newsletter: file(relativePath: {eq: "mailchimpexperts/newsletters.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`)

  return <>
    <MetaData
      data={data}
      location={location}
      type="website"
      linkAlternate={false}
    />
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(servicesSchema)}</script>
      <link rel="alternate" href="https://think-plus.gr/mailchimp-certified-experts-athens-greece/" hreflang="el-gr" />
      <link rel="alternate" href="https://think-plus.gr/en/mailchimp-certified-experts-athens-greece/" hreflang="en" />
    </Helmet>
    <Layout viewport={'full'}>
      <article className="landing-page">
        <HeroFullScreenPhoto
          heroImage={images.hero.childImageSharp.gatsbyImageData}
          heroContent={
            `<div class="landing-page__heading">
                <h1>MAILCHIMP CERTIFIED EXPERTS</h1>
                <p class="h3">Athens | Greece</p>
              </div>
              <div class="landing-page__heading-cta">
                <p class="h6 dec-element--line-horizontal">Call us at +30 210 6101478 <a href="tel: +302106101478" class="btn__theme ml-md-3 mt-3 mt-md-0">Call us</a></p>
                <p class="h6 dec-element--line-horizontal">or fill out this form and we will get back to you as soon as possible <a href="#contact_form_section" class="btn__theme ml-md-3 mt-3 mt-md-0">Contact form</a></p>
              </div>`
          }
        />
        <section className="text-align-center">
          <div className="mailchimp__intro background__theme">
            <div className="container">
              <GatsbyImage image={images.monkey.childImageSharp.gatsbyImageData} alt="Mailchimp" />
              <h2>Email Marketing that suits you,<br /> works and performs for you.</h2>
            </div>
          </div>
          <div className="container small">
            <p>MailChimp is the best tool available for designing and sending eye-catching emails, managing your subscriber lists, and tracking campaign performance. As a certified MailChimp Expert, <Link to="/en/">Think Plus</Link> offers custom solutions to address your business requirements.</p>
            <GatsbyImage image={images.macbook.childImageSharp.gatsbyImageData} alt="" />
          </div>
        </section>
        <section className="mailchimp__together background__theme text-align-center">
          <h3>‌Getting Started</h3>
          <p className="h6">Get your account up and running quickly so you can build your list.</p>
          <h3>Email Strategy</h3>
          <p className="h6">Ensure your campaigns effectively reach your targeted audience.</p>
          <h3>Template Design</h3>
          <p className="h6">Create mobile-friendly, branded, reusable email templates.</p>
          <h3>Integrations</h3>
          <p className="h6">Integrate your list with your website and sync data with other apps.</p>
          <h3>List Verification</h3>
          <p className="h6">Protect your sending reputation and avoid being blocked.</p>
          <h3>One-on-one Training</h3>
          <p className="h6">Learn how to use MailChimp.</p>
          <h3>End-to-end Service</h3>
          <p className="h6">We can handle all the details of your email marketing campaigns.</p>
        </section>
        <section className="container small mailchimp__contactus text-align-center">
          <GatsbyImage image={images.iphones.childImageSharp.gatsbyImageData} alt="" />
          <strong>Do you need help with list migration, custom forms, inbox inspections, list segmentation, reporting, RSS-to-email campaigns, merge tags, video, A/B testing, or automated workflows?</strong>
          <p className="h6">We can help!</p>
          <Link to="/en/contact-us" className="btn btn__theme">Contact us</Link>
        </section>
        <div className="background__theme">
          <div className="container">
            <GatsbyImage image={images.newsletter.childImageSharp.gatsbyImageData} alt="" />
          </div>
        </div>
        <OurPartners headerTitle="CLIENTS" />
        <Testimonials classname="background__theme--dark" />
        <WhyThinkPlus />
        <ContactForm />
      </article>
    </Layout>
  </>;
}

export default pageMailchimpCertified